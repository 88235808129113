.Cell {
  height: 50px;
  width: 50px;
  padding: 0px;
}

.Cell-background-div {
  position: relative;
  height: 100%;
  width: 100%;
  background: lime;
}

.Cell-div-inner {
  position: absolute; 
  top: 0; 
  left: 0;
  transition: opacity 0.7s ease;
  border: 1px solid;
  border-radius: 0px;
  border-color: black;
  background: linear-gradient(0deg, #0000000C 0%, #FFFFFF4C 38%, #FFFFFF66 60%, #00000041 100%);
}
