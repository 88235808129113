.GameSetup {
  color: black;
  text-align: center;
  zoom: 1.3;
}

.GameSetup h1 {
  font-size: 4rem;
  font-weight: 100;
  margin-bottom: 0.1em;
  margin-top: 0;
}

.GameSetup h2 {
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 0.1em;
  margin-top: 40px;
}

.GameSetup button {
  border: none;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  width: 140px;
  margin: 5px 2px 2px 2px;
  letter-spacing: 1px;
  outline: none;
  background: #079cff;
  color: #fff;
  box-shadow: 0 6px #0d00ff;
  border-radius: 5px;
  padding-top: 4px;
}

.GameSetup button:hover {
  background-color: #0d00ff;
}

.GameSetup button:disabled {
  background: #079cff;
  color: #0d00ff;
  box-shadow: 0 6px #079cff;
  cursor: default;
}

.board-size-container {
  text-align: center;
  display: inline-block;
  margin-top: 0;
}

.opponent-container {
  text-align: center;
  display: inline-block;
  margin-top: 0;
}

#start {
  width: 140px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  background: #0d00ff;
  box-shadow: 0 6px #079cff;
}

#start:hover {
  background: #079cff;
}
