.PlayerControl {
  margin: 0px 0px 50px;
  border-collapse: collapse;
  width: 100%;
}

.PlayerButton {
  height: 50px;
  width: 50px;
  opacity: 100%;
  cursor: pointer;
  border: 0px;
  margin: 0px;
}

.hover {
  height: 55px;
}

.disabled {
  opacity: 20%;
  cursor: default;
}

.text-left {
  width: 180px;
  margin-left: 0px;
  margin-right: auto;
}
.score-text-right {
  width: 60px;
  margin-left: auto;
  margin-right: 0px;
}

.score-value-right {
  font-size: 3rem;
  width: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: 0px;
}
